<template>
  <FurnitureComponentsFCHeader />
  <main class="main-wrapper">
    <slot />
  </main>
  <FurnitureComponentsFCFooter />
  <ModulesUserLogin
    :show="showLoginModal"
    @update:show="
      $event ? user.showLoginModal() : user.hideLoginModal()
    "
    @hide="user.hideLoginModal"
  />
  <CookieModal />
</template>

<script lang="ts" setup>
import {useBaseStore} from '~/modules/BaseModule/base.store';
import CookieModal from '~/modules/Cookies/components/CookieModal.vue';
import {useUserStore} from '~/modules/User/user.store';
import {useLauncherInfoStore} from '~/store/launcher-info';

const launcherInfo = useLauncherInfoStore();
const base = useBaseStore();
const user = useUserStore();
const showLoginModal = computed(() => user.isShowLoginModal);

onBeforeMount(async () => {
  const f = async () => await launcherInfo.update();
  await f();
});
</script>

<style lang="scss" scoped></style>
